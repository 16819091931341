import type * as Blockly from 'blockly/core'

const toolbox: Blockly.utils.toolbox.ToolboxDefinition = {
  kind: 'categoryToolbox',
  contents: [
    {
      kind: 'category',
      name: '%{BKY_CATEGORY_CONTROLS}',
      colour: '120',
      contents: [
        {
          kind: 'block',
          type: 'controls_if',
        },
        {
          kind: 'block',
          type: 'controls_whileUntil',
        },
        {
          kind: 'block',
          type: 'controls_repeat_ext',
        },
        {
          kind: 'block',
          type: 'controls_for',
        },
      ],
    },
    {
      kind: 'category',
      name: '%{BKY_CATEGORY_LOGIC}',
      colour: '210',
      contents: [
        {
          kind: 'block',
          type: 'logic_compare',
        },
        {
          kind: 'block',
          type: 'logic_operation',
        },
        {
          kind: 'block',
          type: 'logic_negate',
        },
        {
          kind: 'block',
          type: 'logic_boolean',
        },
        {
          kind: 'block',
          type: 'logic_ternary',
        },
      ],
    },
    {
      kind: 'category',
      name: '%{BKY_CATEGORY_MATH}',
      colour: '230',
      contents: [
        {
          kind: 'block',
          type: 'math_number',
        },
        {
          kind: 'block',
          type: 'math_arithmetic',
        },
        {
          kind: 'block',
          type: 'math_single',
        },
        {
          kind: 'block',
          type: 'math_trig',
        },
        {
          kind: 'block',
          type: 'math_constant',
        },
      ],
    },
    {
      kind: 'category',
      name: '%{BKY_CATEGORY_VARIABLES}',
      custom: 'VARIABLE',
      colour: '330',
    },
    {
      kind: 'category',
      name: '%{BKY_CATEGORY_FUNCTIONS}',
      custom: 'PROCEDURE',
      colour: '290',
    },
    {
      kind: 'category',
      name: '%{BKY_CATEGORY_ACT}',
      colour: '80',
    },
    {
      kind: 'category',
      name: '%{BKY_CATEGORY_INTERACTIONS}',
      colour: '170',
    },
    {
      kind: 'category',
      name: '%{BKY_CATEGORY_DATA}',
      colour: '60',
    },
  ],
}

export default toolbox
